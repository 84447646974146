import { Outlet } from 'react-router-dom'
import { useGetScreeningResultStatus } from '../../api/ester-b2b-api/private/screeningResult/screeningResultQueries'
import { useEffect } from 'react'
import { useScreeningStore } from '../../state/store'

export const PortalLayout = () => {
  const { data: screeningStatusResponse, isLoading } = useGetScreeningResultStatus()
  const { setScreeningStatus } = useScreeningStore()

  useEffect(() => {
    if (isLoading) {
      setScreeningStatus('LOADING')
    } else if (screeningStatusResponse?.status) {
      setScreeningStatus(screeningStatusResponse.status)
    }
  }, [screeningStatusResponse?.status, setScreeningStatus, isLoading])

  return <Outlet />
}
