import styled from 'styled-components'
import { theme } from '../../../theme/theme'
import { isIFAQ } from '@estercare/ester-shared'
import { useGetGeneralPage } from '../../../api/contentful/contentfulQueries'
import {
  useGetActiveHealthCallsQuery,
  usePostHealthCallQuery,
} from '../../../api/ester-b2b-api/private/healthCalls/healthCallQueries'
import { FAQ } from '../../../components/Faq'
import { Header } from '../../../components/Header'
import { ComponentLayout } from '../../../components/layouts/ComponentLayout'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { PreviewGuard } from '../../../components/PreviewGuard'
import { HealthCallStatus } from '../../../api/ester-b2b-api/private/healthCalls/types'
import { BodyLarge, BodyLargeMedium, Title3, Title4, Title6 } from '../../../components/styles/Typography'
import { ServiceGuide } from '../../../components/ServiceGuide'
import { OnClickButton } from '../../../components/buttons/onclick/OnClickButton'
import { QRCode } from '../../../components/icons/QRCode'
import { useState } from 'react'
import { Modal } from '../../../components/Modal'
import { Input } from '../../../components/Input'
import { FilledCheckbox } from '../../../components/icons/FilledCheckbox'
import { isMobile } from '../../../utils/devices'
import { useWindowWidth } from '../../../hooks/useWindowWidth'
import { datadogRum } from '@datadog/browser-rum'

export const HealthCall = () => {
  const { data: healthCallPage, isLoading: isHealthCallPageLoading } = useGetGeneralPage('halsosamtal')
  const { data: healthCalls, isLoading: isHealthCallsLoading } = useGetActiveHealthCallsQuery()
  const { mutate: postHealthCallQuery, isPending: isHealthCheckLoading } = usePostHealthCallQuery({
    onSuccess: () => setFormSubmited(true),
    onError: (error) => {
      datadogRum.addError(error)
      setHealthCallError(true)
    },
  })

  const [HealthCallError, setHealthCallError] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [formSubmited, setFormSubmited] = useState(false)
  const [formData, setFormData] = useState({
    personalNumber: '',
    phoneNumber: '',
  })
  const width = useWindowWidth()
  const mobile = isMobile(width)

  const VALID_PHONE_NUMBER = /^(\+?\d{1,3})?[-\s]?((\d{2,3})[-\s]?)?(\d{2,3})[-\s]?(\d{2,3})[-\s]?(\d{2,4})$/
  const VALID_PERSONAL_NUMBER = /^(19|20)?(\d{2})(\d{2})(\d{2})[-]?(\d{4})$/

  if (isHealthCallPageLoading || isHealthCallsLoading) return <LoadingSpinner />
  const healthCallPageContent = healthCallPage?.items?.[0]?.fields

  if (!healthCallPageContent) return <PreviewGuard />
  if (!healthCalls) throw new Error('Could not fetch health calls')

  const { title, description, contentBlocks } = healthCallPageContent
  if (!title) return <PreviewGuard />

  const numberOfAvailableHealthCalls = healthCalls?.filter(
    (healthCall) => healthCall.status === HealthCallStatus.AVAILABLE
  ).length
  const isHealthCallAvailable = Boolean(numberOfAvailableHealthCalls)

  const healthCallText = `Du har ${numberOfAvailableHealthCalls}/3 digitala hälsosamtal kvar att använda.`

  const steps = [
    {
      title: 'Boka ett hälsosamtal',
      description:
        'Här kontaktar du oss för bokning. Vi återkommer sedan med en bokningsbekräftelse via SMS och en tid för ditt hälsosamtal.',
      content: (
        <OnClickButton
          buttonStyle={{ minWidth: mobile ? 'auto' : '180px' }}
          onClick={() => setOpenDialog(true)}
          text={mobile ? 'Boka samtal' : 'Boka hälsosamtal'}
          secondary
          buttonColor={theme.color.white}
        />
      ),
    },
    {
      title: 'Ladda ner vår app',
      description:
        'Sök på “EsterCare” i App Store eller Google Play eller skanna QR-koden för att ladda ner appen direkt:',
      content: <QRCode />,
    },
    {
      title: 'Logga in och genomför ditt samtal',
      description:
        'Du loggar enkelt in i vår app med ditt BankID och genomför sedan samtalet i appen. Du kan ansluta till mötet 5 minuter innan din bokade tid.',
    },
  ]

  const completedText = {
    title: '3 av 3 hälsosamtal genomförda',
    description:
      'Du har genomfört dina tre hälsosamtal som bekostats av din arbetsgivare. Du får tillgång till ytterligare tre samtal nästa år.',
  }

  return (
    <>
      <Header
        title={title}
        description={description}
        backButtonLink="/portal/halsotjanster"
        backgroundColor={theme.color.beigeLight}
        noPaddingBottom={isHealthCallAvailable}
      />
      {isHealthCallAvailable && (
        <ComponentLayout paddingTop={8}>
          <BodyLargeMedium>{healthCallText}</BodyLargeMedium>
        </ComponentLayout>
      )}
      <ComponentLayout paddingTop={0}>
        {isHealthCallAvailable ? (
          <>
            <StyledTitle6>Hur genomför jag hälsosamtalet?</StyledTitle6>
            <ServiceGuide steps={steps} />
          </>
        ) : (
          <ServiceGuide text={completedText} />
        )}
      </ComponentLayout>
      <ComponentLayout>
        {contentBlocks &&
          contentBlocks.map((block, index) => isIFAQ(block) && <FAQ showSupportLink key={index} {...block.fields} />)}
      </ComponentLayout>
      {openDialog && (
        <Modal
          onClose={() => {
            setOpenDialog(false)
            setFormSubmited(false)
            setFormData({ personalNumber: '', phoneNumber: '' })
            setHealthCallError(false)
          }}
        >
          <ModalContainer>
            {!formSubmited ? (
              <>
                <Title4>Dina uppgifter</Title4>
                <StyledBodyLarge>
                  Fyll i dina uppgifter så återkommer vi till dig med en <br />
                  bokningsbekräftelse inom kort.
                </StyledBodyLarge>

                {HealthCallError && (
                  <StyledErrorBodyLarge>
                    Något gick fel, vänligen kontrollera att dina uppgifter är korrekta.
                  </StyledErrorBodyLarge>
                )}

                <StyledInput
                  label="Personnummer"
                  name="personnummer"
                  value={formData.personalNumber}
                  onChange={(e) => setFormData((prev) => ({ ...prev, personalNumber: e.target.value }))}
                  type="text"
                  placeholder="19XXXXXX-XXXX"
                />

                <Spacer />

                <StyledInput
                  label="Telefonnummer"
                  name="telefonnummer"
                  value={formData.phoneNumber}
                  onChange={(e) => setFormData((prev) => ({ ...prev, phoneNumber: e.target.value }))}
                  type="text"
                  placeholder="Ange telefonnummer"
                />

                <ButtonContainer>
                  <OnClickButton
                    loading={isHealthCheckLoading}
                    disabled={
                      !VALID_PERSONAL_NUMBER.test(formData.personalNumber) ||
                      !VALID_PHONE_NUMBER.test(formData.phoneNumber)
                    }
                    text="Skicka"
                    onClick={() => {
                      postHealthCallQuery({
                        personalNumber: formData.personalNumber,
                        phoneNumber: formData.phoneNumber,
                      })
                    }}
                  />
                </ButtonContainer>
              </>
            ) : (
              <SuccessSubmited>
                <FilledCheckbox size={80} />
                <Title3>Tack, vi återkommer inom kort!</Title3>
                <BodyLarge>
                  Vi skickar en bokningsbekräftelse till dig via SMS. <br />
                  Därefter genomför du hälsosamtalet i vår EsterCare-app.
                </BodyLarge>
              </SuccessSubmited>
            )}
          </ModalContainer>
        </Modal>
      )}
    </>
  )
}

const StyledTitle6 = styled(Title6)`
  margin-bottom: ${theme.spacing.xsmall}px;
`
const StyledInput = styled(Input)`
  background: ${theme.color.beigeLight};
`
const ModalContainer = styled.div`
  width: 416px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    width: 100%;
  }
`

const StyledBodyLarge = styled(BodyLarge)`
  margin-bottom: ${theme.spacing.medium}px;
`
const StyledErrorBodyLarge = styled(StyledBodyLarge)`
  color: ${theme.color.red};
`
const Spacer = styled.div<{ space?: string }>`
  height: ${({ space = '16' }) => `${space}px`};
`

const ButtonContainer = styled.div`
  margin-top: ${theme.spacing.medium}px;
  button {
    width: 100%;
  }
`
const SuccessSubmited = styled.div`
  text-align: center;
  ${Title3} {
    margin-bottom: ${theme.spacing.xsmall}px;
    margin-top: ${theme.spacing.medium}px;
  }
`
