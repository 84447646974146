/* eslint-disable @typescript-eslint/no-unused-vars */
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import {
  Blockquote,
  BodyLarge,
  RichTextLink as AnchorTag,
  Title1,
  Title2,
  Title3,
  Title4,
  Title5,
  Title6,
} from '../styles/Typography'
import { theme } from '../../theme/theme'
import { Link } from 'react-router-dom'
import { isInternalLink, stripPortalDomain } from '../../utils/links'

const StyledTitle1 = styled(Title1)`
  margin-top: ${theme.spacing.small}px;
  margin-bottom: ${theme.spacing.xsmall}px;
  font-size: ${theme.fontSize.xxlarge}px;
  line-height: 46px;
`

const StyledTitle2 = styled(Title2)`
  margin-top: ${theme.spacing.small}px;
  margin-bottom: ${theme.spacing.xsmall}px;
  font-size: ${theme.fontSize.xlarge}px;
  line-height: 38px;
`
const StyledTitle3 = styled(Title3)`
  margin-top: ${theme.spacing.mediumLarge}px;
  margin-bottom: ${theme.spacing.xsmall}px;
  font-size: ${theme.fontSize.large}px;
  line-height: 32px;
`
const StyledTitle4 = styled(Title4)`
  margin-top: ${theme.spacing.mediumLarge}px;
  margin-bottom: ${theme.spacing.xsmall}px;
  font-size: ${theme.fontSize.mediumLarge}px;
  line-height: 30px;
`
const StyledTitle5 = styled(Title5)`
  margin-top: ${theme.spacing.mediumLarge}px;
  margin-bottom: ${theme.spacing.xsmall}px;
  font-size: ${theme.fontSize.medium}px;
  line-height: 28px;
`
const StyledTitle6 = styled(Title6)`
  margin-top: ${theme.spacing.medium}px;
  margin-bottom: ${theme.spacing.xsmall}px;
  font-size: ${theme.fontSize.default}px;
  line-height: 26px;
`

const StyledBody = styled(BodyLarge)`
  margin-bottom: ${theme.spacing.medium}px;
`

export const StyledBold = styled.span`
  font-weight: 500;
`

export const StyledReactLink = styled(Link)`
  color: ${theme.color.plumMid};
  text-decoration: underline;
  text-decoration-color: ${theme.color.plumMid};
`

export const Divider = styled.hr`
  border-bottom: 0.5px solid ${theme.color.grey1};
  width: 100%;
  margin: ${theme.spacing.xlarge}px 0px;
`

export const MarkdownToReact = ({ markdown }: { markdown: string }) => {
  return (
    <ReactMarkdown
      components={{
        h1: ({ node, ref, ...props }) => <StyledTitle1 {...props} />,
        h2: ({ node, ref, ...props }) => <StyledTitle2 {...props} />,
        h3: ({ node, ref, ...props }) => <StyledTitle3 {...props} />,
        h4: ({ node, ref, ...props }) => <StyledTitle4 {...props} />,
        h5: ({ node, ref, ...props }) => <StyledTitle5 {...props} />,
        h6: ({ node, ref, ...props }) => <StyledTitle6 {...props} />,
        p: ({ node, ref, ...props }) => <StyledBody {...props} />,
        strong: ({ node, ref, ...props }) => <StyledBold {...props} />,
        li: ({ node, ref, ...props }) => (
          <BodyLarge>
            <li {...props} />
          </BodyLarge>
        ),
        ol: (props) => (
          <StyledBody>
            <ol {...props} />
          </StyledBody>
        ),
        a: ({ href, children }) => {
          const strippedHref = stripPortalDomain(href ?? '')
          return isInternalLink(strippedHref) ? (
            <StyledReactLink to={strippedHref}>{children}</StyledReactLink>
          ) : (
            <AnchorTag href={href} target="_blank">
              {children}
            </AnchorTag>
          )
        },
        em: ({ node, ref, ...props }) => <em {...props} />,
        blockquote: (props) => <Blockquote {...props} />,
        br: (props) => <br {...props} />,
        hr: (props) => <Divider {...props} />,
      }}
    >
      {markdown}
    </ReactMarkdown>
  )
}
