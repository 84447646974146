import { HeartPalpitation, Home, Paper, QuestionMark, Search, StethoscopePlus } from '../../components/icons'
import { MenuItem } from './types'
import { useSelectScreeningStatus } from '../../state/selectors'

export const useEmployeeMenu = (): MenuItem[] => {
  const screeningStatus = useSelectScreeningStatus()
  return [
    { name: 'Hem', path: '/portal', icon: Home },
    ...(screeningStatus === 'HANDLED'
      ? [{ name: 'Din hälsa', path: '/portal/din-halsa/analys', icon: HeartPalpitation }]
      : []),
    { name: 'Hälsotjänster', path: '/portal/halsotjanster', icon: StethoscopePlus },
    { name: 'Utbildning', path: '/portal/utbildning', icon: Paper },
    { name: 'Kunskapsbank', path: '/portal/kunskapsbank', icon: Search },
    { name: 'Support', path: '/portal/support', icon: QuestionMark },
  ]
}
